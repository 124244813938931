import React from "react";

import Button from "../../components/button";
import Icon from "../../components/icon";
import CodePenEmbed from "../../components/codepen-embed";
import SplitHero from "../../components/split-hero";
import Tag, { TagGroup } from "../../components/tag";
import Text from "../../components/text";

function BouncyCastleSection() {
  return (
    <SplitHero>
      <SplitHero.Media>
        <SplitHero.Media.Content>
          <CodePenEmbed title="CSS 3D Bouncy Castle" penID="aEqZaz" />
        </SplitHero.Media.Content>
      </SplitHero.Media>

      <SplitHero.Content>
        <SplitHero.Heading>
          <Text variant="h3" component="h2" shade="light">
            Bouncy Castle.
          </Text>
        </SplitHero.Heading>

        <Text variant="p" shade="mid">
          An animated 3D bouncy castle created with just HTML and CSS. I started
          this experiment as a means to stress test the capabilities of the
          transform property in CSS.
          <TagGroup>
            <Tag>3D</Tag>
            <Tag>CSS</Tag>
            <Tag>CSS Animation</Tag>
            <Tag>CSS Transforms</Tag>
          </TagGroup>
        </Text>

        <Button
          href="https://codepen.io/steveeeie/pen/aEqZaz"
          as="a"
          target="__blank"
          rel="nofollow"
          marginTop={4}
          small
        >
          View On CodePen <Icon icon="codepen" marginLeft={0} />
        </Button>
      </SplitHero.Content>
    </SplitHero>
  );
}

export default BouncyCastleSection;
