import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Button from "../../components/button";
import Icon from "../../components/icon";
import SplitHero from "../../components/split-hero";
import Tag, { TagGroup } from "../../components/tag";
import Text from "../../components/text";

function AndyKaufmanSection() {
  const { ahScreenshot1 } = useStaticQuery(
    graphql`
      query {
        ahScreenshot1: file(
          relativePath: { eq: "air-hockey-screenshot-1.jpg" }
        ) {
          ...fluidImage
        }
      }
    `
  );

  return (
    <SplitHero>
      <SplitHero.Content>
        <SplitHero.Heading>
          <Text variant="h3" component="h2" shade="light">
            Multiplayer Air Hockey.
          </Text>
        </SplitHero.Heading>

        <Text variant="p" shade="mid">
          A multiplayer WebGL air hockey game that consists of two parts. The
          server handles game creation, socket connections and the physics
          simulation. The client handles the UI and rendering of the table.
          <TagGroup>
            <Tag>3D</Tag>
            <Tag>Express</Tag>
            <Tag>Game</Tag>
            <Tag>Javascript</Tag>
            <Tag>React</Tag>
            <Tag>Socket.io</Tag>
            <Tag>Three.js</Tag>
          </TagGroup>
        </Text>

        <Button
          href="https://github.com/Steveeeie/webgl-air-hockey-client"
          as="a"
          target="__blank"
          rel="nofollow"
          marginTop={4}
          small
        >
          View On GitHub <Icon icon="github" marginLeft={0} />
        </Button>
      </SplitHero.Content>

      <SplitHero.Media>
        <SplitHero.Media.Content>
          <Img
            fluid={ahScreenshot1.childImageSharp.fluid}
            alt="Multiplayer Air Hockey"
          />
        </SplitHero.Media.Content>
      </SplitHero.Media>
    </SplitHero>
  );
}

export default AndyKaufmanSection;
