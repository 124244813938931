import React from 'react';
import Helmet from 'react-helmet';

import Footer from '../../components/footer';
import Page from '../../components/page';
import SocialLinks from '../../components/social-links';
import Text from '../../components/text';

import AirHockeySection from './section-hockey';
import AndyKaufmanSection from './section-andy-kaufman';
import BouncyCastleSection from './section-bouncy-castle';
import PageTransitionSection from './section-page-transition';
import SCTimelineSection from './section-timeline';

function PersonalProjectsPage() {
  return (
    <Page>
      <Page.Container>
        <Helmet title="Steve Meredith - Projects"></Helmet>

        <Page.Title>
          <Text variant="h2" component="h1">
            Projects.
          </Text>
        </Page.Title>

        <Page.Section>
          <AndyKaufmanSection />
        </Page.Section>

        <Page.Section>
          <SCTimelineSection />
        </Page.Section>

        <Page.Section>
          <PageTransitionSection />
        </Page.Section>

        <Page.Section>
          <BouncyCastleSection />
        </Page.Section>

        <Page.Section>
          <AirHockeySection />
        </Page.Section>

        <Page.NextAction>
          <Page.SocialBox>
            <SocialLinks />
          </Page.SocialBox>
        </Page.NextAction>

        <Footer />
      </Page.Container>
    </Page>
  );
}

export default PersonalProjectsPage;
