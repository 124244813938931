import React from 'react';
import styled from 'styled-components';

const Frame = styled.iframe`
  transform: scale(1.01);
`;

function CodePenEmbed({ penID, title }) {
  return (
    <Frame
      scrolling="no"
      title={title}
      src={`//codepen.io/steveeeie/embed/${penID}/?height=480&theme-id=dark&default-tab=result&embed-version=2`}
      style={{ width: '100%', height: '100%' }}>
      See the Pen <a href={`https://codepen.io/steveeeie/pen/${penID}/`}>{title}</a> by Steve
      Meredith (<a href="https://codepen.io/steveeeie">@steveeeie</a>) on{' '}
      <a href="https://codepen.io">CodePen</a>.
    </Frame>
  );
}

export default CodePenEmbed;
